import React, { useEffect, useRef, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import twoFAStyles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import VerificationCodeInput from './VerificationCodeInput';
import { FormatIDs } from '../../../../types';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA, SetUpCategories } from '../../../../analytics/analyticsConstants';
import Alert from '../../../Shared/Alert';

export enum VerificationCodeFlow {
    login = 1,
    setup,
}

const VERIFICATION_CODE_FLOW_TEXT: Record<
    VerificationCodeFlow,
    { title: FormatIDs; body: FormatIDs; logErrorAnalytics: () => void }
> = {
    [VerificationCodeFlow.setup]: {
        title: '2FA.SetUp.VerificationCode.Title',
        body: '2FA.SetUp.VerificationCode.Body',
        logErrorAnalytics: () => analytics.logSetUpTwoFA(SetUpCategories.verificationCode, ButtonsTwoFA.closeError),
    },
    [VerificationCodeFlow.login]: {
        title: '2FA.Login.Title',
        body: '2FA.Login.Body',
        logErrorAnalytics: () => analytics.logLoginTwoFA(ButtonsTwoFA.closeError),
    },
};

export interface VerificationCodeProps {
    onClick: (code: string) => void;
    verificationCodeFlow: VerificationCodeFlow;
    footer?: React.ReactNode;
    errorFormatID?: FormatIDs;
}

const VerificationCode: React.FunctionComponent<VerificationCodeProps> = (props) => {
    const { onClick, verificationCodeFlow, footer, errorFormatID } = props;
    const [code, setCode] = useState([...Array(6)]);
    const [loading, setLoading] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { error } = routeUtils.parseQuery<{ error?: string }>(location.search);

    // put button on focus so that user can move to next stage with enter key
    useEffect(() => {
        if (!code.some((code) => code === undefined) && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [code]);

    return (
        <div
            className={classNames(
                wrapperStyles.content_wrapper,
                wrapperStyles.input_block,
                twoFAStyles.generic_container
            )}
        >
            <div className={twoFAStyles.header}>
                <FormattedMessage id={VERIFICATION_CODE_FLOW_TEXT[verificationCodeFlow].title} />
            </div>
            <div className={[twoFAStyles.body_text, twoFAStyles.div_margin].join(' ')}>
                <FormattedMessage id={VERIFICATION_CODE_FLOW_TEXT[verificationCodeFlow].body} />
            </div>
            {error && (
                <Alert
                    className={twoFAStyles.alert}
                    description={<FormattedMessage id={errorFormatID ?? '2FA.SetUp.VerificationCode.Failed'} />}
                    type="error"
                    showIcon
                    onClose={() => {
                        VERIFICATION_CODE_FLOW_TEXT[verificationCodeFlow].logErrorAnalytics();
                    }}
                />
            )}
            <VerificationCodeInput code={code} setCode={setCode} />
            <AnalyticsButton
                ref={buttonRef}
                className={twoFAStyles.primary_button_container}
                type="primary"
                onClick={() => {
                    onClick(code.join(''));
                    setLoading(true);
                }}
                loading={loading}
                disabled={code.some((code) => code === undefined)}
                size="large"
            >
                <span className={twoFAStyles.primary_button_text}>
                    <FormattedMessage id={loading ? '2FA.SetUp.VerificationCode.Verifying' : 'Common.Verify'} />
                </span>
            </AnalyticsButton>
            {footer}
        </div>
    );
};

export default VerificationCode;
