import type { AliasToken } from 'antd/es/theme/interface';
import { ThemeConfig } from 'antd';
import colors from './colors.json';
import fonts from './fonts.json';

// Returns the box shadow string with 20% opacity
const getBoxShadow = (color: string) => `0 0 0 2px ${color}32`;

// Removes the "px" from string, returning only the number
const removePx = (str: string) => parseInt(str.slice(0, -2));

const SEED_TOKEN: Partial<AliasToken> = {
    colorPrimary: colors.primary,
    colorBgBase: colors.black,
    colorBgContainerDisabled: colors.grey,
    colorTextBase: colors.white,
    colorTextHeading: colors.heading_color,
    colorTextDisabled: colors.light_white,
    colorTextPlaceholder: colors.grey,
    colorLink: colors.primary,

    fontSize: removePx(fonts.base),
    fontSizeLG: removePx(fonts.large),
    fontFamily: fonts.fontFamily,

    borderRadius: removePx(fonts.borderRadius),
};

export const THEME_CONFIG: ThemeConfig = {
    components: {
        Input: {
            colorBorder: colors.light_white,
            addonBg: colors.black,
            activeShadow: getBoxShadow(colors.primary),
            errorActiveShadow: getBoxShadow(colors.error_red),
        },
        InputNumber: {
            colorBorder: colors.light_white,
            addonBg: colors.black,
            activeShadow: getBoxShadow(colors.primary),
            errorActiveShadow: getBoxShadow(colors.error_red),
        },
        Select: {
            colorBorder: colors.light_white,
            optionActiveBg: colors.light_grey,
            optionSelectedBg: colors.primary,
            optionSelectedColor: colors.black,
        },
        Button: {
            primaryColor: colors.black,
            primaryShadow: 'none',
            borderColorDisabled: colors.light_grey,
        },
        Checkbox: {
            colorBorder: colors.light_white,
        },
        Tooltip: {
            colorText: colors.white,
            colorBgSpotlight: colors.black,
        },
        Dropdown: {
            controlItemBgHover: colors.grey,
            paddingXXS: 0, // padding between dropdown container and menu
            borderRadiusSM: 0, // rounded corners of item
        },
        Alert: {
            withDescriptionIconSize: 22,
            withDescriptionPadding: 16,
        },
    },
    token: SEED_TOKEN,
};
