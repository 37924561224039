import { Alert as AntdAlert } from 'antd';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { AlertProps } from 'antd/lib';

const Alert: React.FunctionComponent<AlertProps> = (props) => {
    return <AntdAlert closable={{ closeIcon: <CloseIcon /> }} {...props} />;
};

export default Alert;
