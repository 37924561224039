import React, { useCallback, useRef, useState } from 'react';
import { Input } from 'antd';
import { routeUtils } from 'tds-common-fe';
import { useHistory } from 'react-router-dom';
import styles from './Login.styl';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import twoFAStyles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { SUPPORT_EMAIL } from '../../../../config/constants';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import URL from '../../../../api/urls';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import { TWO_FA_ROUTES } from '../../../Routes/urls';
import Alert from '../../../Shared/Alert';

const LoginRecoveryKey: React.FunctionComponent = () => {
    const history = useHistory();
    const { formatMessage } = useFormatMessage();
    const { error, ...queryParams } = routeUtils.parseQuery<{ error?: string }>(location.search);
    const [recoveryKey, setRecoveryKey] = useState('');
    const htmlFormRef = useRef<HTMLFormElement>(null);

    const actionURL = routeUtils.makeQueryPath(URL.TWO_FA_LOGIN, {
        ...getScreeningEagleHeaders(),
        ...queryParams,
    });

    const cancelPath = routeUtils.makeQueryPath(TWO_FA_ROUTES.LOGIN, { ...queryParams });

    const handleSubmit = useCallback(() => {
        analytics.logLoginRecoveryKeyTwoFA(ButtonsTwoFA.verify);
        const form = htmlFormRef.current;
        if (form !== null) {
            const recoveryKeyInput: HTMLInputElement | null = form.querySelector('[name="recoveryKey"]');
            if (recoveryKeyInput) {
                recoveryKeyInput.value = recoveryKey;
            }
            form.submit();
        }
    }, [recoveryKey]);

    return (
        <>
            <div className={classNames(wrapperStyles.content_wrapper, wrapperStyles.input_block)}>
                <div className={twoFAStyles.header}>
                    <FormattedMessage id="2FA.LoginRecoveryKey.Title" />
                </div>
                {error && (
                    <Alert
                        className={twoFAStyles.alert}
                        description={<FormattedMessage id="2FA.LoginRecoveryKey.Failed" />}
                        type="error"
                        showIcon
                        onClose={() => analytics.logLoginRecoveryKeyTwoFA(ButtonsTwoFA.closeError)}
                    />
                )}
                <div className={styles.recover_key_input_container}>
                    <div>
                        <FormattedMessage id="2FA.LoginRecoveryKey.RecoveryKey" />
                    </div>
                    <div className={styles.input}>
                        <Input
                            type="text"
                            size="large"
                            value={recoveryKey}
                            onChange={(e) => setRecoveryKey(e.target.value)}
                        />
                    </div>
                    <div className={styles.subtitle}>
                        <FormattedMessage id="2FA.LoginRecoveryKey.Caption" />
                    </div>
                </div>
                <AnalyticsButton
                    disabled={recoveryKey.length <= 0}
                    className={twoFAStyles.primary_button_container}
                    type="primary"
                    onClick={handleSubmit}
                    size="large"
                >
                    <span className={twoFAStyles.primary_button_text}>
                        <FormattedMessage id="Common.Verify" />
                    </span>
                </AnalyticsButton>
                <AnalyticsButton
                    className={twoFAStyles.primary_button_container}
                    type="link"
                    onClick={() => {
                        analytics.logLoginRecoveryKeyTwoFA(ButtonsTwoFA.cancel);
                        history.push(cancelPath);
                    }}
                    size="large"
                >
                    <span className={twoFAStyles.primary_button_text}>
                        <FormattedMessage id="Common.Cancel" />
                    </span>
                </AnalyticsButton>
                <a
                    className={styles.footer_container_left_aligned}
                    href={`mailto:${SUPPORT_EMAIL}?subject=${formatMessage({ id: '2FA.LoginRecoveryKey.Title' })}`}
                    onClick={() => analytics.logLoginRecoveryKeyTwoFA(ButtonsTwoFA.contactSupport)}
                >
                    <FormattedMessage id="2FA.LoginRecoveryKey.ContactSupport" />
                </a>
            </div>
            <form ref={htmlFormRef} action={actionURL} method="POST" className={styles.hidden_form}>
                <input name="recoveryKey" type="password" autoComplete="off" tabIndex={-1} />
            </form>
        </>
    );
};

export default LoginRecoveryKey;
